import React from 'react'
import './footer.css'
import resume from '../images/resume.pdf'


const Footer = ({ siteTitle }) => (
    <div className="footer">
        <nav className="footerGroup">
            <ul className="footerLinks">
                <li /><a target="_blank" rel="noopener noreferrer" href="https://www.dribbble.com/jherrc">Dribbble</a>
                <li /><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/heanjurrera">Instagram</a>
                <li /><a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/heanjurrera">Twitter</a>
                <li /><a target="_blank" rel="noopener noreferrer" href="https://www.soundcloud.com/heanjurrera">Soundcloud</a>
                <li /><a target="_blank" rel="noopener noreferrer" href="https://www.last.fm/user/dreamcountry">Last.fm</a>
            </ul>
            <ul className="bottomnav">
                <li /><a href={resume} target="_blank" rel="nofollow noopener noreferrer">Resume</a>
                <li /><a href="mailto:juanherrc@gmail.com">Hello</a>
            </ul>
        </nav>
        <div className="extrasFooter">
            <p>© 2021 <a href="mailto:juanherrc@gmail.com">Juan Herrera</a> 
                <br />Hosted with <a href="https://www.netlify.com/">Netlify</a></p>
        </div>
    </div>
    )
    
export default Footer