import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'
import './layout.css'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
render = {data => (
  <>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        { name: 'description', content: 'Juan Herrera — Product designer' },
        { name: 'keywords', content: 'product, design, ui, ux' },
      ]}
    />
    <Header siteTitle={data.site.siteMetadata.title} />
      <div>{children}</div>
    <Footer />
  </>
)}
/>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout