import React from 'react'
import Link from 'gatsby-link'
import name from '../images/name.svg'
import './header.css'
import resume from '../images/resume.pdf'
import doc from '../images/JuanHerrera-PortfolioAppendix.pdf'



const Header = ({ siteTitle }) => (
<div className="header">
    <nav className="menuGroup">
     <div className="logo"><Link to="/"><img src={name} width="140" /></Link></div>
     {/* <div className="logo"><Link to="/"><h1>JUAN HERRERA</h1></Link></div>  */}
     <ul className="menu">
        <li /><a href={resume} target="_blank" rel="nofollow noopener noreferrer">Resume</a>
        <li /><a href="mailto:juanherrc@gmail.com">Hello</a>
     </ul>
    </nav>
</div>
)

export default Header
